import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import MetaInfo from "vue-meta-info"; 
Vue.use(MetaInfo)

import VueI18n from 'vue-i18n'  
Vue.use(VueI18n)
let browserLanguage;
try {
  browserLanguage = window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage || window.navigator.systemLanguage || 'en';
} catch (error) {
  browserLanguage = 'en'
}
const systemLang = (browserLanguage === 'zh-CN' || browserLanguage === 'zh') ? 'cn' : 'en'

store.commit('changeLanguage',systemLang)

import i18n from './locale/index'; 
//element ui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element)
//公共混入方法
import mixin from './utils/mixin.js'
Vue.mixin(mixin);

//引入网络请求  
import '@/utils/request' 

//公共样式
import "@/assets/css/index.scss"

//swiper

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

//百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: "rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR"
})

Vue.config.productionTip = false

//vue-video-player

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(VueVideoPlayer)

//皮肤
import '@/assets/theme/theme.scss'

import './assets/icon/iconfont.css'
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
