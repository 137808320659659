import VueI18n from 'vue-i18n'
import Vue from 'vue'


// 引入需要语言包也可是js文件，export default抛出语言包的对象
import en from './en.json'
import zh from'./zh-Hans.json'

Vue.use(VueI18n)

let browserLanguage;
//系统语言
try {
  browserLanguage = window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage || window.navigator.systemLanguage || 'en';
} catch (error) {
  browserLanguage = 'en'
}
const systemLang = (browserLanguage === 'zh-CN' || browserLanguage === 'zh') ? 'cn' : 'en'

//获取本地选择的语言
var lang = systemLang;

const i18n = new VueI18n({
  locale: lang, // 语言标识
  fallbackLocale: 'zh-CN',
  silentTranslationWarn: true,
  messages: {
    en: Object.assign(en),
    "zh-CN": Object.assign(zh)
  }
})
export default i18n

