
		function $date(timeStamp,format){
			if('' + timeStamp.length <= 10){
				timeStamp = + timeStamp * 1000;
			}else{
				timeStamp = + timeStamp;
			}
			let _date = new Date(timeStamp),
				Y = _date.getFullYear(),
				m = _date.getMonth() + 1,
				d = _date.getDate(),
				H = _date.getHours(),
				i = _date.getMinutes(),
				s = _date.getSeconds();
			
			m = m < 10 ? '0' + m : m;
			d = d < 10 ? '0' + d : d;
			H = H < 10 ? '0' + H : H;
			i = i < 10 ? '0' + i : i;
			s = s < 10 ? '0' + s : s;
		
			return format.replace(/[YmdHis]/g, key=>{
				return {Y,m,d,H,i,s}[key];
			});
		}


const mixin = {
	
	data(){
		return {
			
		}
	},
	filters:{
		$date(date){
			if(!date) return ''
			return $date(date,'Y-m-d H:i:s')
		}
	},
	methods:{
		//验证手机号  手机号格式  传参 data 返回值 布尔
		phone(data){
			var phone = /^1[3456789]\d{9}$/
			return phone.test(data)
		},
		//验证日期  格式20210101 传参data  返回值 布尔
		dateTime(data){
			var dataTime = /^((((1[6-9]|[2-9]\d)\d{2})(0?[13578]|1[02])(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})(0?[13456789]|1[012])(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})0?2(0?[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))0?229))$/
			return dataTime.test(data)
		},
		//验证邮箱
		email(data){
			var email = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
			return email.test(data)
		},
		//校验中英文姓名
		isName(name) {
			return /[a-zA-Z\u4E00-\u9FA5]+$/.test(name)
		},
		//去空格
		trim(str) {
			return str.replace(/(^\s*)|(\s*$)/g, '')
		  },
		//纯数字校验
		  checkIsNumber(value){
			var numberReg = /^[0-9]*$/;
			return numberReg.test(value);
		  },
		//验证 身份证
		isUserId(value){
			var numberReg = /^\d{15}|\d{}18$/;
			return numberReg.test(value);
		  },
		//验证 不能为空
		paramsValidate(params){
			let flag = true;
			for (var key in params) {
			  if (params[key] != '0' && !params[key]) {
				// console.log(key, '为空的属性');
				return false; // 终止程序
			  }
			}
			return flag;
		  },
		  navTo(url,data){
			// let query = JSON.stringify(data)
			// console.log(data);
			this.$router.push({path:url,query:data})
		}


		// methods  
	},
	
	
	
}

export default mixin;