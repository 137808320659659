<template>
  <div class="dhs-footer">
    <el-row :gutter="0" class="footer" type="flex">
      <el-col :span="8" :offset="0" class="footer-left">
        <div class="wz1"><!-- 地址信息 -->{{ $t("RSfooter.Address") }}</div>
        <!-- <div class="wz2">{{$t("RSfooter.Address1")}}</div>
        <div class="wz2">{{$t("RSfooter.Address2")}}</div>
        <div class="wz2">{{$t("RSfooter.Address3")}}</div>
        <div class="wz2">
          Zurich:Hagenholzstrasse 85A,CH-8050 Zürich, Switzerland
        </div> -->

        <div class="wz2" v-for="(item, index) in address" :key="index">
          {{ item.city + "：" + (item.adress || "") + (item.details || "") }}
        </div>

        <!-- <div class="footer-nav">
          <div class="footer-title">产品</div>
          <span @click="navTo('/goods?productMenuId=1')"> 乒乓运动</span>
          <span @click="navTo('/goods?productMenuId=10')"> 羽球运动</span>
          <span> 足篮排球</span>
          <span> 举重运动 </span>
        </div> -->

        <!-- <div class="footer-nav">
          <div class="footer-title">服务</div>
          <span @click="navTo('/ProductTechnology')"> 产品科技 </span>
          <span @click="navTo('/productQA')"> 产品指南</span>
          <span @click="navTo('/securityCheck')"> 防伪查询 </span>
          <span @click="navTo('/joinus')"> 客户查询 </span>
          <span @click="navTo('/productQA')"> 产品使用介绍 </span>
        </div>
        品牌故事 品牌资讯 品牌荣誉 线下体验 社会责任 联系方式
        <div class="footer-nav">
          <div class="footer-title">关于我们</div>

          <div class="about_me">
            <span @click="navTo('/Memorabilia')">品牌故事</span>
            <span @click="navTo('/newList')">品牌资讯</span>
            <span @click="navTo('/brandhonor')">品牌荣誉</span>
            <span @click="navTo('/offlineExperience')">线下体验</span>
            <span @click="navTo('/responsibility')">社会责任</span>
            <span @click="navTo('/contactus')">联系方式</span>
            <span v-for="(item, index) in h" :key="index" @click="func(index)">
              {{ item.remark }}
            </span>
          </div>
          <span> 品牌介绍 </span>
          <span @click="navTo('/star')"> 明星风采 </span>
          <span @click="navTo('/contactus')"> 联系我们 </span>
          <span @click="navTo('/news')"> 品牌资讯 </span>
          <span> 赛事历程 </span>
        </div>-->
      </el-col>
      <el-col :span="5" :offset="0" class="footer-right">
        <div class="wz1"><!-- 联系我们 -->{{ $t("RSfooter.Contact Us") }}</div>
        <div class="wz2">
          <!-- 商务合作：alpsentek@alpsentek.com -->{{
            $t("RSfooter.For Business Cooperation")
          }}<a
            :href="'mailto:' + $t('RSfooter.For Business Cooperation Email')"
            >{{ $t("RSfooter.For Business Cooperation Email") }}</a
          >
        </div>
        <div class="wz2">
          <!-- 简历投递：talent@alpsentek.com -->{{ $t("RSfooter.To Join Us")
          }}<a :href="'mailto:' + $t('RSfooter.To Join Us Email')">{{
            $t("RSfooter.To Join Us Email")
          }}</a>
        </div>
        <div class="wz2">
          <!-- 媒体合作：pr@alpsentek.com -->{{
            $t("RSfooter.For PR connection")
          }}<a :href="'mailto:' + $t('RSfooter.For PR connection Email')">{{
            $t("RSfooter.For PR connection Email")
          }}</a>
        </div>
        <!--  <div class="right-goumai">
          <div class="footer-title">关注我们</div>
          <div>
            <img v-if="skin" src="../assets/icon/png/weibo.png" alt="" />
            <img v-if="!skin" src="../assets/icon/png/darkweibo.png" alt="" />
            <span>新浪微博</span>
          </div>
          <div>
            <img v-if="skin" src="../assets/icon/png/weixin.png" alt="" />
            <img v-if="!skin" src="../assets/icon/png/darkweixin.png" alt="" />
            <span>微信公众号</span>
          </div>
        </div>

        <div class="right-goumai">
          <div class="footer-title">购买</div>
          <div>
            <img v-if="skin" src="../assets/icon/png/jd.png" alt="" />
            <img
              v-if="!skin"
              src="../assets/icon/png/darkjingdong.png"
              alt=""
            />
            <span>京东旗舰店</span>
          </div>
          <div>
            <img v-if="skin" src="../assets/icon/png/tianmao.png" alt="" />
            <img v-if="!skin" src="../assets/icon/png/darktianmao.png" alt="" />
            <span>天猫旗舰店</span>
          </div>
        </div>-->
      </el-col>
      <el-col :span="3" :offset="0" class="partner">
        <div>
          <img src="../assets/images/wewm.jpg" alt="" />
          <div style="text-align: center">
            <!-- 关注微信公众号，了解更多 -->{{
              $t("RSfooter.Follow our WeChat account to find out more. ")
            }}
          </div>
        </div>
        <!--  <div class="">
          <img
            src="https://shanxia.oss-cn-shanghai.aliyuncs.com/50da33e0-9e18-4f95-b1b2-9116f1491c22.jpg"
            alt=""
          />
          <span>国际乒乓球联合会</span>
        </div>
        <div>
          <img
            src="https://shanxia.oss-cn-shanghai.aliyuncs.com/9f1f0dfc-1c48-4c4e-86f4-37288562a7cd.jpg"
            alt=""
          />
          <span>中国乒乓球协会</span>
        </div>
        <div>
          <img
            src="https://shanxia.oss-cn-shanghai.aliyuncs.com/33c88b3c-a641-453d-a485-624da7e76a8f.jpg"
            alt=""
          />
          <span>国际羽毛球联合会</span>
        </div>
        <div>
          <img
            src="https://shanxia.oss-cn-shanghai.aliyuncs.com/3d904711-34e2-4fc9-a671-f55d11adab19.jpg"
            alt=""
          />
          <span>中国羽毛球协会</span>
        </div>
        <div>
          <img
            src="https://shanxia.oss-cn-shanghai.aliyuncs.com/1d8c04d4-4d33-4f1a-8421-f89e30361431.jpg"
            alt=""
          />
          <span>国际举重联合会</span>
        </div>
        <div>
          <img
            src="https://shanxia.oss-cn-shanghai.aliyuncs.com/00d68ec4-8c3b-427d-a203-95458e67b781.jpg"
            alt=""
          />
          <span>中国举重协会</span>
        </div> -->
      </el-col>
    </el-row>
    <el-row type="flex" class="copyright" justify="center">
      <!-- <el-col :span="5" :offset="0">
        <span class="copyright-left">Copyright©2021 上海锐思股份有限公司</span>
      </el-col>
      <el-col :span="11" :offset="0" class="copyright-right">
        <span>沪公网安备31011002000177号</span>
        <span>沪ICP备09030782号</span>
      </el-col> -->
      <div>
        <!-- @ 2022 AlpsenTek Co., All rights reserved. Site Map Designed by cherryQ -->
        {{ $t("banquan") }}
      </div>
    </el-row>

    <!-- <el-row :gutter="20" type="flex" justify="center">
      <el-col :span="20" :offset="0">
        <el-collapse v-model="activeNames" @change="handleChange">
  <el-collapse-item title="一致性 Consistency" name="1">
    <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
    <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
  </el-collapse-item>
  <el-collapse-item title="反馈 Feedback" name="2">
    <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
    <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
  </el-collapse-item>
  <el-collapse-item title="效率 Efficiency" name="3">
    <div>简化流程：设计简洁直观的操作流程；</div>
    <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
    <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
  </el-collapse-item>
  <el-collapse-item title="可控 Controllability" name="4">
    <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
    <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
  </el-collapse-item>
</el-collapse>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      h: "",
      address: [],
    };
  },
  computed: {
    language() {
      return this.$store.state.Language;
    },
  },
  created() {
    this.get();
    this.$http
      .get(`/address/list?language=${this.$store.state.Language}`)
      .then((res) => {
        this.address = res.rows;
        console.log("获取地址", res);
      });
  },
  methods: {
    appHeight() {
      document.getElementById("app").clientHeight;
    },
    get() {
      this.$http.get("/appAgreement/list").then((res) => {
        this.h = res.rows;
        // console.log(this.h);
      });
    },
    func(index) {
      console.log(this);
      this.$router.push({ path: "/useragreement?index=" + index });
    },
  },

  computed: {
    skin() {
      let show = true;
      if (this.$store.state.skin == "light-theme") {
      }
      if (this.$store.state.skin == "dark-theme") {
        show = false;
      }
      return show;
    },
  },
};
</script>
<style></style>
<style lang="scss" scoped>
.wz1 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 8px;
  cursor: pointer;
}
.wz2 {
  font-size: 12px;
  margin-bottom: 15px;
}

.dhs-footer {
  color: rgb(181, 180, 185);
  background: rgb(45, 48, 57);
}
.footer {
  justify-content: center;
  // align-items: center;
}
.footer-left {
  // background-color: #000000;
  display: flex;
  // justify-content: center;
  // align-items: flex-start;
  flex-direction: column;
  padding-left: 2%;
}
.footer-nav {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0px 20px 40px;
  height: 100%;
  .footer-title {
    padding: 14px 0px;
    color: #000000;
    font-weight: 500;
    font-size: 14px;
  }
  span {
    color: rgb(138, 138, 138);
    padding: 2px 0px;
    cursor: pointer;
    display: block;
    font-size: 12px;
    width: 45%;
  }
}
.footer-right {
  // background-color: aqua;
  display: flex;
  // justify-content: flex-start;
  flex-direction: column;
  padding-left: 1%;
  a {
    color: rgb(181, 180, 185);
    text-decoration: none;
  }
}
.right-goumai {
  display: flex;
  flex-direction: column;
  flex: 1;
  .footer-title {
    padding: 14px 0px;
    color: black;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    cursor: auto;
  }
  div {
    padding: 4.5px 0px;
    cursor: pointer;
    font-size: 10px;
  }
  img {
    height: 30px;
    width: 30px;
    vertical-align: middle;
  }
  span {
    padding: 0px 20px;
  }
}
.footer-title span:hover {
  color: rgb(196, 0, 10);
}
.about_me {
  // display: flex;
  flex-wrap: wrap;
  span {
    width: 45%;
  }
}
.copyright {
  border-top: 2px solid rgb(34, 34, 42);
  align-items: center;
  // padding: 10px 20px;
  padding: 12px;
  font-size: 13px;
}
.copyright-right {
  padding: 0px 20px;
  span {
    padding: 0px 40px;
  }
}
.footer-nav span:hover {
  color: rgb(196, 0, 10);
}

.partner {
  justify-content: center;
  // background-color: aquamarine;
  display: flex;
  flex-wrap: wrap;
}
.partner div {
  width: 100%;
  display: flex;
  // padding: 5px 10px;
  flex-direction: column;
  align-items: center;
  img {
    // height: 57%;
    width: 57%;
    margin-top: 25px;
  }
  div {
    font-size: 10px;
    margin-top: 10px;
    font-weight: 300;
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    flex-direction: column;
  }
  .footer-left {
    // flex-direction: column;
    width: 100%;
    padding-left: 5%;
  }
  .footer-nav {
    flex-direction: column;
    width: 30%;
    // padding-left: 5%;
    padding: 20px 0px;
    flex-wrap: wrap;
  }
  .footer-right {
    width: 100%;
    padding-left: 5%;
    .right-goumai {
      padding-left: 10px;
    }
  }
  .partner {
    width: 100%;
    justify-content: center;
    div {
      width: 80%;
      margin-bottom: 25px;
    }
  }
  .copyright {
    width: 100%;
    flex-direction: column;
    div {
      text-align: center;
      width: 100%;
      img {
        margin-left: 7px;
      }
    }
  }
}
</style>
