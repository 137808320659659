<template>
	<div class="scroll" :class="{ fixedHeader: cov }">
		<el-row class="header" type="flex">
			<el-col :span="2" :offset="0" class="phone-header-nav">
				<i class="el-icon-s-unfold" style="color: #fff;" @click="leftMenu"></i>
			</el-col>
			<el-col :span="4" :offset="0" class="header-image ">
				<div class="header-img header-nav" style="width: 90%;" @click="navtoHome">
					<img src="../assets/images/DHSlogo.png" alt="" width="100%" height="100%"
						:class="{ headeranimation: cov }" />
				</div>
			</el-col>
			<el-col :span="8" :offset="0" class="header-nav">
				<router-link to="/" tag="div" :class="{ act: routerPath == '/' }" v-popover:popover1><!-- 首页 -->{{$t('RSTop.Home')}}</router-link>

				<!-- <el-popover ref="popover1" placement="bottom" trigger="hover">
          <div class="goodsPopover">
            <div @click="navTo('/goods?productMenuId=1')">
              <img src="../assets/icon/png/pingpangqiu.png" alt="" />
              <span>乒乓</span>
            </div>

            <div @click="navTo('/goods?productMenuId=10')">
              <img src="../assets/icon/png/yumaoqiu.png" alt="" />
              <span>羽球</span>
            </div>

            <div>
              <img src="../assets/icon/png/zulanqiumoren.png" alt="" />
              <span>足篮排</span>
            </div>

            <div>
              <img src="../assets/icon/png/tiyu-juzhong.png" alt="" />
              <span>举重</span>
            </div>
          </div> 
        </el-popover>-->
				<router-link to="/technology" tag="div" :class="{ act: routerPath == '/technology' }"><!-- 技术 -->{{$t('RSTop.technology')}}</router-link>
				<!-- <router-link to="/application" tag="div" :class="{ act: routerPath == '/application' }"> --><!-- 应用 --><!-- {{$t('RSTop.application')}}</router-link> -->
				<router-link to="/application" tag="div">
					<el-dropdown>
					  <span class="el-dropdown-link" :class="{ act: routerPath == '/application' }">
					    {{$t('RSTop.application')}}   <!-- 应用 -->
					  </span>
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item v-for="item,index in list" :key="index" ><p @click="navTos(item)">{{ item.name }}</p></el-dropdown-item>
					  </el-dropdown-menu>
					</el-dropdown>
				</router-link>
				
				<router-link to="/product" tag="div" :class="{ act: routerPath == '/product' }"><!-- 产品 -->{{$t('RSTop.product')}}</router-link>
				<router-link to="/news" tag="div" :class="{ act: routerPath == '/news' }"><!-- 新闻 -->{{$t('RSTop.news')}}</router-link>
				<router-link to="/about" tag="div" :class="{ act: routerPath == '/about' }"><!-- 关于 -->{{$t('RSTop.about')}}</router-link>
				<router-link to="/joinus" tag="div" :class="{ act: routerPath == '/joinus' }"><!-- 加入 -->{{$t('RSTop.Careers')}}</router-link>
			</el-col>
			<!-- <el-col :span="2" :offset="0" class="phone-header-nav">
        <i class="el-icon-s-unfold" style="color: #fff;" @click="leftMenu"></i>
      </el-col> -->

			<el-col :span="4" :offset="0" class="seach">
				<!-- <div class="seach-1">
          <el-input
            placeholder="搜索"
            suffix-icon="el-icon-search"
            v-model="input3"
            class="input-with-select"
          > -->
				<!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
				<!--  </el-input>
        </div> -->
				<div class="seach-2">
					<a @click="changeLanguage('zh-CN')">中文</a>&nbsp;|&nbsp;<a @click="changeLanguage('en')">EN</a>
					<!-- 中文 | EN -->
					<!-- <el-select v-model="select" placeholder="中文" class="el-select">
            <el-option label="中文" value="1"></el-option>
            <el-option label="英文" value="2"></el-option>
            <el-option label="日文" value="3"></el-option>
          </el-select> -->
					<!-- <el-switch v-model="theme" :active-value="true" :inactive-value="false" @change="changetheme"></el-switch> -->
				</div>
			</el-col>
		</el-row>

		<el-drawer :visible.sync="diaologShow" size="50%" direction="ltr" class="drawer">
			<div class="DHSdrawer">
				<router-link to="/" tag="div" :class="{ act: routerPath == '/' }"><!-- 首页 -->{{$t('RSTop.Home')}}</router-link>
				<router-link to="/technology" tag="div" :class="{ act: routerPath == '/technology' }"><!-- 技术 -->{{$t('RSTop.technology')}}</router-link>
				<router-link to="/application" tag="div" :class="{ act: routerPath == '/application' }"><!-- 应用 -->{{$t('RSTop.application')}}</router-link>
				<router-link to="/product" tag="div" :class="{ act: routerPath == '/product' }"><!-- 产品 -->{{$t('RSTop.product')}}</router-link>
				<router-link to="/news" tag="div" :class="{ act: routerPath == '/news' }"><!-- 新闻 -->{{$t('RSTop.news')}}</router-link>
				<router-link to="/about" tag="div" :class="{ act: routerPath == '/about' }"><!-- 关于 -->{{$t('RSTop.about')}}</router-link>
				<router-link to="/joinus" tag="div" :class="{ act: routerPath == '/joinus' }"><!-- 加入 -->{{$t('RSTop.Careers')}}</router-link>
			</div>
			<template slot="title">
				<div class="phone-header-img" @click="navtoHome">
					<img src="../assets/images/DHSlogo.png" />
				</div>
			</template>
		</el-drawer>
	</div>
</template>

<script>
	// import {Mapmutations,Mapstate} from '../store/index'
	export default {
		data() {
			return {
				routerPath: "",
				select: "",
				input3: "",
				cov: false,
				theme: false,
				diaologShow: false,
				list: []
			};
		},
		mounted() {
			window.addEventListener("scroll", this.rollingheight, true);
		},
		watch: {
			$route(route) {
				// console.log(route.path);
				this.routerPath = route.path;
			},
			// theme(val){
			//   console.log(val);
			//   this.changetheme()
			// }
			language(n,o){
				this.getList();
			}
		},
		computed: {
			language(){
				return this.$store.state.Language
			}
		},
		created() {
			this.changetheme();
			this.getList();
		},
		methods: {
			//切换语言
			changeLanguage(vl){
				this.$i18n.locale = vl
				if(vl === 'zh-CN'){
					this.$store.commit("changeLanguage",'cn') //切换中文
				}else{
					this.$store.commit("changeLanguage",'en') //切换英文
				}
			},
			getList() {  //获取应用列表数据
			  let language = this.$store.state.Language
			  this.$http.get("application/list?language="+ language).then((res) => {
			    this.list = res.rows;
			  });
			  console.log(this.list)
			},
			navTos(item) {  //进入应用详情页
			  this.$router.push({
			    path: "/applicationDetail",
			    query: {
			      username: item.code,
			    },
			  });
			},
			changetheme() {
				this.theme = !this.theme;
				// console.log(!this.theme);
				if (this.theme) {
					document.getElementById("app").setAttribute("class", "light-theme");
					this.$store.commit("SET_SKIN", "light-theme");
					//   console.log(this.$store.state.skin,'store.statestore.statestore.statestore.statestore.state');
					//  console.log('111111111111111111111111111112222222222222222222222222222222222222222222222222');
					this.theme = !this.theme;
					return;
				} else {
					document.getElementById("app").setAttribute("class", "dark-theme");
					this.$store.commit("SET_SKIN", "dark-theme");
					// console.log(this.$store.state.skin,'store.statestore.statestore.statestore.statestore.state');
					this.theme = !this.theme;
					return;
				}
			},
			navtoHome() {
				this.$router.push({
					path: "/"
				});
			},
			rollingheight(e) {
				if (window.pageYOffset > 30) {
					this.cov = true;
				} else {
					this.cov = false;
				}
			},
			leftMenu() {
				this.diaologShow = true;
			},
		},
	};
</script>
<style>
	.el-drawer {
		background: rgba(49, 49, 49, 0.5) !important;
		backdrop-filter: blur(1px) brightness(30%);
	}


	.el-textarea__inner,
	.el-input__inner {
		background: transparent !important;
	}

	.drawer {
		z-index: 9999;
	}

	.drawer .el-drawer__close-btn {
		display: none;
	}

	.el-input__inner {
		height: 25px !important;
		line-height: 25px !important;
	}

	.el-input__icon {
		line-height: 25px !important;
	}

	.el-select {
		width: 50%;
		margin-left: 14%;
	}

	.input-with-select.el-input.el-input-group.el-input-group--append {
		margin-right: 20px;
		box-shadow: 0px 0px 2px #ccc;
		border-radius: 8px;
	}

	.el-select {
		box-shadow: 0px 0px 2px #ccc;
		border-radius: 8px;
	}
</style>
<style lang="scss" scoped>
	.fixedHeader {
		// background: #eee;
		background: rgba(49, 49, 49, 0.7) !important;
		opacity: 1;
		width: 100%;
		position: fixed;
		top: 0;
		z-index: 10;
		transition: 0.8s;

		.header {
			height: 60px !important;
			margin: 0px !important;
			transition: 0.8s;
		}

		.header-img {
			img {
				transform: translate(-28%, 0);
				width: 50% !important;
				transition: 0.8s;
			}
		}

		.header-nav {
			div {
				padding: 12px 0 !important;
			}
		}
	}

	.fixed-header::before {
		content: "";
		background: rgb(59, 59, 59);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0.8;
		z-index: -1;
	}

	.scroll {
		// background: rgba(49, 49, 49, 0);
		box-shadow: 0px 0px 1px #ccc;
		color: #000000;
		// position: absolute;
		// top:0;
		// width: 100;
		background: rgba(49, 49, 49, 0.5);
		opacity: 1;
		width: 100%;
		position: fixed;
		top: 0;
		z-index: 999;
		transition: 0.8s;

		.header {
			height: 80px;
			// margin: 15px;
			transition: 0.8s;
		}

		.header-img {
			img {
				width: 75%;
				transition: 0.8s;
			}
		}
	}

	.header {
		justify-content: center;
		align-items: center;
	}

	.header-img {
		padding: 5px 0px;
		width: 100%;
		display: flex;
		justify-content: center;
		cursor: pointer;

		img {
			height: 100%;
			width: 25%;
			padding: 2px auto;
		}
	}

	.header-nav {
		display: flex;
		width: 49%;
		justify-content: flex-start;
		align-items: center;
		// text-align: end;
		font-size: 14px;
		text-align: center;
		color: rgb(255, 255, 255);
		transition: all 0.5s;
		// padding-left: 3%;
		
		::v-deep .el-dropdown{
			span{
				color: #fff;
			}
		}
		::v-deep .el-dropdown:hover{
			span{
				color: rgb(125, 149, 255) !important;
			}
		}
		div{
			transition: all 0.3s;
		}

		div {
			flex: 1;
			padding: 26px 0px;
			cursor: pointer;
		}
	}

	.seach {
		padding-left: 1.3%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.seach-1 {
		width: 60%;
	}

	.seach-2 {
		font-weight: 700;
		color: rgb(255, 255, 255);
		font-size: 14px;
		padding-left: 13%;
		width: 50%;
		
		a{
			text-decoration: none;
			
		}
		a:hover{
			cursor: pointer;
		}
	}

	.header-nav div:hover {
		// background: rgb(35, 80, 199);
		font-weight: 700;
		color: rgb(125, 149, 255) !important;
		font-size: 18px !important;
		transition: all 0.3s; 
	}

	.act {
		// background: rgb(35, 80, 199);
		// color: rgb(35, 80, 199);
		font-weight: 800;
		color: rgb(125, 149, 255) !important;
		font-size: 18px;
	}

	.phone-header-nav {
		display: none;
	}

	.goodsPopover {
		display: flex;
		flex-direction: column;

		div {
			padding: 10px;
			margin: 0;
			cursor: pointer;

			img {
				margin-right: 10px;
				height: 16px;
				width: 16px;
			}
		}
	}

	.goodsPopover div:hover {
		background: rgb(223, 1, 16);
		color: #fff;
	}

	@media screen and (max-width: 1000px) {
		.header {
			width: 100% !important;
			justify-content: flex-start;
			// div {
			//   // flex: 1;
			// }
		}

		.header-nav {
			display: none;
		}

		.phone-header-nav {
			display: block;
			text-align: center;
			font-size: 20px;
		}

		.header-image {
			width: 20%;
		}

		.header-img {
			justify-content: flex-start;
			padding-left: 10%;
			width: 100%;
			height: 80%;

			// width: ;
			img {
				width: 80% !important;
			}
		}

		.phone-header-nav {
			width: 10%;
		}

		.seach {
			width: 69%;

			div {
				// flex: 1;
				// width: 100%;
				text-align: right;
			}

			.el-select {
				width: 70%;
			}

			.input-with-select {
				width: 100%;
			}
		}
	}

	.DHSdrawer {
		z-index: 9999 !important;
		display: flex;
		flex-direction: column;
		color: rgb(255, 255, 255);

		div {
			padding: 10px 40px;
			flex: 1;
		}
	}

	.phone-header-img {
		// background-color: #ccc;
		width: 100px;

		img {
			width: 100%;
		}
	}
</style>
