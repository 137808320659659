<template>
  <div class="zuoce">
    <!-- <div class="online">
      <div class="show">
        <img class="wx" src="../assets/icon/png/Hweixin.png" alt="" />
        <p class="p">联系微信</p>
      </div>
      <div class="hide">
        <img src="../assets/images/wxerweima.png" alt="" />
      </div>
    </div>
    <div>
      <el-backtop :right="0" :bottom="100">
        <div
          style="
             {
              height: 50px;
              width: 50px;
              background-color: #eee;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: rgb(196, 0, 10);
            }
          "
        >
          <img class="up-img" src="../assets/icon/png/up.png" alt="" />
        </div>
      </el-backtop>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    mouseOver() {
      this.show = true;
    },
    mouseleave() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.up-img {
  height: 50px;
  width: 30px;
}
.zuoce {
  z-index: 99999999 !important;
}
.online {
  // height: 120px;
  overflow: hidden;
  position: fixed;
  right: -160px;
  bottom: 160px;
  cursor: pointer;
  z-index: 999 !important;
}
.show {
  height: 130px;
  width: 30px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #bbb;
  margin-top: 20px;
  float: left;

  .wx {
    width: 80%;
    padding: 4px;
  }
  .p {
    margin: 0;
    text-align: center;
    color: #fff;
  }
}
.hide {
  width: 160px;
  height: 160px;
  // display: none;
  float: left;
  img {
    width: 100%;
    height: 100%;
  }
}

.online:hover {
  position: fixed;
  right: 0px;
  bottom: 160px;
  transition: 1s;
}

@media screen and (max-width: 1024px) {
  .zuoce {
    display: none;
  }
}
</style>
