<template>
  <div id="app">
    <RSTop></RSTop>
    <router-view class="main-content" />

    <contactus></contactus>
    <RSfooter class="dhs" v-if="isF"></RSfooter>
  </div>
</template>
<script>
import contactus from "@/components/contactus";
import RSTop from "@/components/RSTop.vue";
import RSfooter from "@/components/RSfooter.vue";

export default {
  components: {
    contactus,
    RSTop,
    RSfooter,
  },
  computed: {
    language() {
      return this.$store.state.Language;
    },
  },
  watch: {
    language: {
      handler(newVal, oldVal) {
       this.isF = false
       setTimeout(()=>{
          this.isF = true
       },0)
      },
      deep: true
    },
  },
  data(){
    return {
      isF: true
    }
  },
  created() {
    return;
    const loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
    });
    this.fullscreenLoading = true;
    setTimeout(() => {
      loading.close();
    }, 2000);
  },
};
</script>

<style lang="scss">
#app {
	
  // font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei",
  //   "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  // background: rgb(249,249,249);
  // font-family: 'Noto Sans', 'Noto Sans Regular', 'Lucida Grande', 'Noto Sans Unicode', Geneva, Verdana, sans-serif !important;
  font-family: 'Source Han Sans Medium','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
  // font-family: 'Source Han Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
  min-height: 100%;
  height: auto;
  // position: relative;
}
.main-content {
  min-height: 470px !important;
}
html,
body {
  margin: 0;
  padding: 0;
  // height: 100%;
  width: 100%;
  overflow-x: hidden;
}
ul > li {
  list-style: none;
  // background: $base-menu-background;
}
</style>
