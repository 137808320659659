import Vue from "vue";
import VueRouter from "vue-router";
import axios from '../utils/request'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      cnName: '首页'
    },
    component: () => import("../views/index.vue"),
  },
  // {
  //   path: "/products",
  //   name: "products",
  //   component: () => import("../views/products.vue"),
  // },
  {
    path: "/product",
    name: "goods",
    meta: {
      cnName: '产品'
    },
    component: () => import("../views/goods.vue"),
  },
  // {
  //   path: "/goodsclass",
  //   name: "goodsclass",
  //   component: () => import("../views/goodsclass.vue"),
  // },
  // {
  //   path: "/securityCheck",
  //   name: "securityCheck",
  //   component: () => import("../views/securityCheck.vue"),
  // },
  // {
  //   path: "/productQA",
  //   name: "productQA",
  //   component: () => import("../views/productQA.vue"),
  // },
  {
    path: "/news",
    name: "news",
    meta: {
      cnName: '新闻'
    },
    component: () => import("../views/newList.vue"),
  },
  {
    path: "/newsDetails",
    name: "newsDetails",
    meta: {
      cnName: '新闻详情'
    },
    component: () => import("../views/news.vue"),
  },
  // {
  //   path: "/ProductTechnology",
  //   name: "ProductTechnology",
  //   component: () => import("../views/ProductTechnology.vue"),
  // },
  // {
  //   path: "/Memorabilia",
  //   name: "Memorabilia",
  //   component: () => import("../views/Memorabilia.vue"),
  // },
  {
    path: "/about",
    name: "about",
    meta: {
      cnName: '关于'
    },
    component: () => import("../views/about.vue"),
  },
  // {
  //   path: "/brandstory",
  //   name: "brandstory",
  //   component: () => import("../views/brandstory.vue"),
  // },
  // {
  //   path: "/brandhonor",
  //   name: "brandhonor",
  //   component: () => import("../views/brandhonor.vue"),
  // },
  // {
  //   path: "/star",
  //   name: "star",
  //   component: () => import("../views/star.vue"),
  // },
  // {
  //   path: "/starDetail",
  //   name: "starDetail",
  //   component: () => import("../views/starDetail.vue"),
  // },
  // {
  //   path: "/offlineExperience",
  //   name: "offlineExperience",
  //   component: () => import("../views/offlineExperience.vue"),
  // },
  // {
  //   path: "/experienceDetail",
  //   name: "experienceDetail",
  //   component: () => import("../views/experienceDetail.vue"),
  // },
  {
    path: "/joinus",
    name: "joinus",
    meta: {
      cnName: '加入我们'
    },
    component: () => import("../views/joinus.vue"),
  },
  {
    path: "/jobDetails",
    name: "jobDetails",
    meta: {
      cnName: '职位详情'
    },
    component: () => import("../views/jobDetails.vue"),
  },
  // {
  //   path: "/distributorDetail",
  //   name: "distributorDetail",
  //   component: () => import("../views/distributorDetail.vue"),
  // },
  // {
  //   path: "/responsibility",
  //   name: "responsibility",
  //   component: () => import("../views/responsibility.vue"),
  // },
  // {
  //   path: "/goodsList",
  //   name: "goodsList",
  //   component: () => import("../views/goodsList.vue"),
  // },
  // {
  //   path: "/useragreement",
  //   name: "useragreement",
  //   component: () => import("../views/useragreement.vue"),
  // },
  {
    path: "/application",
    name: "application",
    meta: {
      cnName: '应用'
    },
    component: () => import("../views/application.vue"),
  },
  {
    path: "/applicationDetail",
    name: "applicationDetail",
    meta: {
      cnName: '应用详情'
    },
    component: () => import("../views/applicationDetail.vue"),
  },
  {
    path: "/technology",
    name: "technology",
    meta: {
      cnName: '技术'
    },
    component: () => import("../views/technology.vue"),
  },
];

const router = new VueRouter({
  // mode: "hash",
  mode: "history",
  routes,
});

// 判断两个时间间隔是否大于24小时
const isMoreThan24Hours = (startTime, endTime) => {
    // 将时间字符串转换为 Date 对象
    const start = new Date(startTime);
    const end = new Date(endTime);

    // 计算时间差（毫秒）
    const timeDifference = end - start;

    // 将毫秒转换为小时
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    // 判断是否大于24小时
    return hoursDifference > 24;
}

// 路由守卫
router.beforeEach((to, from, next) => {
  next();
  const recordTime = localStorage.getItem('recordTime');
  if (!recordTime || isMoreThan24Hours(recordTime,new Date())) {
    axios
      .post("other/record", {
        creact_time: new Date(),
        computer: navigator?.userAgent || "未知",
        type: to?.meta?.cnName,
      })
      .then((res) => {
        console.log("系统==>>", {
          creact_time: new Date(),
          computer: navigator?.userAgent || "未知",
          type: to?.meta?.cnName,
        });
        localStorage.setItem("recordTime", new Date());
      });
  }

});

export default router;
