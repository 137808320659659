import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//系统语言
// const systemLang = window.navigator.language === 'zh-CN' ? 'cn' : 'en'

let browserLanguage;
try {
  browserLanguage = window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage || window.navigator.systemLanguage || 'en';
} catch (error) {
  browserLanguage = 'en'
}
const systemLang = (browserLanguage === 'zh-CN' || browserLanguage === 'zh') ? 'cn' : 'en'

export default new Vuex.Store({
  state: {
    skin: '',
    userInfo: {},
    Language: systemLang //语言 cn中文 en英文
  },
  mutations: {
    SET_SKIN: (state, skin) => {
      state.skin = skin
    },
    changeLanguage(state, vl) {
      state.Language = vl
    }
  },
  actions: {
    Login({ commit }, userInfo) {

    },
  },
  modules: {
  }
})
