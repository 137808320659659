import axios from "axios";
import Vue from "vue";
import store from "@/store";

// axios.defaults.baseURL = "http://localhost:88/";
// axios.defaults.baseURL = "http://xz-ai.info:8100/";
// axios.defaults.baseURL = "https://www.alpsentek.com/api/";
axios.defaults.baseURL = "https://alpsentek.com/prod-api/";
// axios.defaults.baseURL = "http://192.168.31.181:8084/"

let token = localStorage.getItem("token");

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.common["Authorization"] = token;

// request拦截器
axios.interceptors.request.use(
  (config) => {
    //设置携带token
    config.headers.token = token;

    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    // const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      MessageBox.confirm(
        "登录状态已过期，您可以继续留在该页面，或者重新登录",
        "系统提示",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        store.dispatch("LogOut").then(() => {
          location.href = "/";
        });
      });
    } else if (code === 500) {
      // this.$message.error(res);
      return Promise.reject(new Error(res));
    } else if (code !== 200) {
      Notification.error({
        title: msg,
      });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;
export default axios
//使用方法  this.$http.post/get('url',{data}).then(res=>{})
